import { FontSizes, PrimaryButton } from "office-ui-fabric-react";
import React from "react";
import { Strings } from "../../../Strings";
import { style } from "../../../styles";
import "../../../styles/DocumentStyles";
import { createResponsiveStyle } from "../../../styles/ResponsiveStyle";
import { ScreenSize } from "../../../styles/ScreenSize";
import { Spacing } from "../../../styles/Spacing";

export function HeroBanner() {
  return (
    <div className={heroContainerClass}>
      <div className="image" />
      <div className="content">
        <h1>{Strings.pages.home.HeroTitle}</h1>
        <h3 style={{ marginBottom: Spacing.Large }}>
          {Strings.pages.home.HeroText}
        </h3>
        <PrimaryButton>{Strings.pages.home.LearnMore}</PrimaryButton>
      </div>
    </div>
  );
}

const heroContainerClass = style(
  {
    $nest: {
      "& .image": {
        backgroundImage: "url('/img/main-page-laptop.jpeg')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundColor: "#f8f8f8",
        width: "100%",
      },
    },
  },
  createResponsiveStyle(ScreenSize.Small, {
    $nest: {
      "& .image": {
        height: 200,
      },
    },
  }),
  createResponsiveStyle(ScreenSize.Medium, {
    $nest: {
      "& .image": {
        height: 300,
      },
    },
  }),
  createResponsiveStyle(ScreenSize.Large, {
    $nest: {
      "& .image": {
        height: 400,
      },
    },
  }),
  createResponsiveStyle(
    ScreenSize.xLarge,
    {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      height: 500,
      $nest: {
        "& .image": {
          backgroundPosition: "left center",
          backgroundSize: "contain",
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: -1,
        },
        "& .content": {
          position: "absolute",
          right: "calc(5%)",
          maxWidth: 650,
        },
      },
    },
    true
  )
);
