import { initializeIcons } from "office-ui-fabric-react";
import { cssRule, fontFace } from "./";
import { createResponsiveStyle } from "./ResponsiveStyle";
import { ScreenSize } from "./ScreenSize";
import { Spacing } from "./Spacing";

initializeIcons();

cssRule(
  "html, body, #___gatsby",
  {
    fontSize: Spacing.Large,
  },
  createResponsiveStyle(ScreenSize.Small, { padding: Spacing.Micro }),
  createResponsiveStyle(
    ScreenSize.Medium,
    {
      padding: Spacing.Small,
    },
    true
  )
);

cssRule("h1,h2,h3,h4,h5,h6", {
  fontWeight: "lighter",
  margin: 0,
  marginBottom: 4,
});

cssRule(
  "h1",
  {
    fontWeight: 300,
  },
  createResponsiveStyle(ScreenSize.Small, { fontSize: "8vw" }),
  createResponsiveStyle(ScreenSize.Medium, { fontSize: "6vw" }),
  createResponsiveStyle(ScreenSize.Large, { fontSize: "4vw" }),
  createResponsiveStyle(ScreenSize.xLarge, { fontSize: "3vw" }, true)
);

cssRule(
  "h2",
  {
    fontWeight: 300,
  },
  createResponsiveStyle(ScreenSize.Small, { fontSize: "7vw" }),
  createResponsiveStyle(ScreenSize.Medium, { fontSize: "4.2vw" }),
  createResponsiveStyle(ScreenSize.Large, { fontSize: "3.5vw" }),
  createResponsiveStyle(ScreenSize.xLarge, { fontSize: "2vw" }, true)
);

cssRule(
  "h3",
  {
    fontWeight: 300,
  },
  createResponsiveStyle(ScreenSize.Small, { fontSize: "6vw" }),
  createResponsiveStyle(ScreenSize.Medium, { fontSize: "3.5vw" }),
  createResponsiveStyle(ScreenSize.Large, { fontSize: "2.5vw" }),
  createResponsiveStyle(ScreenSize.xLarge, { fontSize: "1.5vw" }, true)
);

fontFace({
  fontFamily: "Coves Light",
  src: "url('/fonts/Coves Light.otf') format('opentype')",
});
