import { FontSizes, PrimaryButton } from "office-ui-fabric-react";
import React from "react";
import { Strings } from "../../../Strings";
import { style } from "../../../styles";
import "../../../styles/DocumentStyles";
import { createResponsiveStyle } from "../../../styles/ResponsiveStyle";
import { ScreenSize } from "../../../styles/ScreenSize";
import { Spacing } from "../../../styles/Spacing";

export function DataDrivenBanner() {
  return (
    <div className={heroContainerClass}>
      <div className="image" />
      <div className="content">
        <h1>{Strings.pages.home.DataDrivenTitle}</h1>
        <h3 style={{ marginBottom: Spacing.Large }}>
          {Strings.pages.home.DataDrivenText}
        </h3>
        <PrimaryButton>{Strings.pages.home.LearnMore}</PrimaryButton>
      </div>
    </div>
  );
}

const heroContainerClass = style(
  {
    $nest: {
      "& .image": {
        backgroundImage: "url('/img/main-page-data.jpeg')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100%",
      },
    },
  },
  createResponsiveStyle(ScreenSize.Small, {
    $nest: {
      "& .image": {
        height: 200,
      },
      "& .content": {
        $nest: {
          "& .title": {
            marginBottom: Spacing.Small,
            $nest: {
              "& > span": {
                fontSize: FontSizes.xxLarge,
              },
            },
          },
          "& .description > span": {
            fontSize: FontSizes.large,
          },
        },
      },
    },
  }),
  createResponsiveStyle(ScreenSize.Medium, {
    $nest: {
      "& .image": {
        height: 300,
      },
    },
  }),
  createResponsiveStyle(ScreenSize.Large, {
    $nest: {
      "& .image": {
        height: 400,
      },
    },
  }),
  createResponsiveStyle(
    ScreenSize.xLarge,
    {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      height: 600,
      $nest: {
        "& .image": {
          height: "100%",
          width: "100%",
          zIndex: -1,
        },
        "& .content": {
          position: "absolute",
          left: "calc(5%)",
          color: "white",
          maxWidth: 650,
          $nest: {
            "& .title": {
              marginBottom: Spacing.Medium,
            },
          },
        },
      },
    },
    true
  )
);
