import React from "react";
import { Helmet } from "react-helmet";
import { Spacing } from "../styles/Spacing";
import { Footer } from "./Footer";
import NavigationBar from "./NavigationBar";

export function PageLayout(props: {
  title: String;
  selectedNavigationKey?: string;
  children: React.ReactNode | React.ReactNode[];
}) {
  return (
    <div>
      <Helmet bodyAttributes={{ class: "ms-Fabric" }}>
        <title>{props.title}</title>
      </Helmet>
      <div style={{ marginBottom: Spacing.Large }}>
        <NavigationBar selectedKey={props.selectedNavigationKey} />
      </div>
      {props.children}
      <Footer />
    </div>
  );
}
