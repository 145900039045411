import React from "react";
import { Spacing } from "../styles/Spacing";

export function ResponsiveGrid(props: {
  children: React.ReactNode | React.ReactNode[];
  snapSize: 2 | 3 | 4;
}) {
  const chunks = chunkChildren(
    (props.children && [].concat(props.children)) || [],
    props.snapSize
  );

  const snapClassSize = 12 / props.snapSize;

  return (
    <div className="ms-Grid ms-textAlignCenter" dir="ltr">
      {chunks.map(chunk => (
        <div
          className={`ms-Grid ms-Grid-col ms-sm12 ms-md12 ms-lg${snapClassSize}`}
          dir="ltr"
        >
          {chunk.map(item => (
            <div
              className={`ms-Grid-col ms-sm12 ms-md${snapClassSize} ms-lg${snapClassSize} ms-textAlignCenter`}
              style={{ marginBottom: Spacing.xxLarge }}
            >
              {item}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

function chunkChildren(children: React.ReactNode[], chunkSize: number) {
  const chunks: Array<React.ReactNode[]> = [];
  children.forEach((item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);
    const chunkSet = chunks[chunkIndex] || [];
    chunkSet.push(item);
    chunks[chunkIndex] = chunkSet;
  });
  return chunks;
}
