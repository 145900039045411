const strings = {
  CompanyName: "Thomik",
  ContactEmail: "hello@thomik.com",
  navigation: {
    ContactUs: "Contact Us",
    Services: "Services",
    Home: "Home",
  },
  footer: {
    copyright: "Copyright © Thomik Inc.",
  },
  pages: {
    home: {
      HeroText:
        "Thomik helps organizations define their technology strategies, innovate on hybrid cloud platforms, solutions development, data insight, sales, and more. Let us help you accelerate your digital transformation!",
      HeroTitle: "Technology that empowers",
      DataDrivenText:
        "Leverage the power of big data in making critical business decisions and realize the full potential of your investments.",
      DataDrivenTitle: "Data Driven Decisions",
      InnovateHybridCloudText:
        "Leverage our cloud experts to adopt cloud best suited for your business needs. We understand the intricacies of data privacy and ownership, designing reliable mission critical services and infrastructure portability.",
      InnovateHybridCloudTitle: "Innovate in Hybrid Cloud",
      InnovateRelentlesslyText:
        "Continously innovate in order to simplify and scale your business with the effective use of technology.",
      InnovateRelentlesslyTitle: "Innovate Relentlessly",
      InvestIntellectText:
        "Enchance your organization's knowledge capital and skills through collaborating with our team of technology and business experts.",
      InvestIntellectTitle: "Invest in your Intellect",
      LearnMore: "Learn More",
      OptimizeTechnicalInvestmentText:
        "Plan and execute on a technology roadmap that serves the needs of your business long term and can adapt to changing market landscape.",
      OptimizeTechnicalInvestmentTitle: "Optimize your Technical Investments",
      TransformBusinessText:
        "We can help you empower your business through technology training, consulting as well as building business solutions that fit your needs.",
      TransformBusinessTitle: "The expertise to tranform your business",
    },
  },
};

export { strings as Strings };
