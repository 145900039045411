import React from "react";
import { Strings } from "../Strings";

export function Footer() {
  return (
    <div
      style={{
        textAlign: "right",
      }}
    >
      {Strings.footer.copyright}
    </div>
  );
}
