import { CommunicationColors } from "@uifabric/fluent-theme";
import React from "react";
import { style } from "../styles";
import { createResponsiveStyle } from "../styles/ResponsiveStyle";
import { ScreenSize } from "../styles/ScreenSize";
import { Spacing } from "../styles/Spacing";

export function OfferingFeatureCard(props: {
  icon: React.ReactElement;
  title: string;
  text: string;
}) {
  return (
    <>
      <div className={offeringCardIconStyle}>{props.icon}</div>
      <h3>{props.title}</h3>
      <div>{props.text}</div>
    </>
  );
}

const offeringCardIconStyle = style(
  {
    color: CommunicationColors.primary,
    marginBottom: Spacing.Medium,
    $nest: {
      "& span, & span svg": {
        height: "12vw",
        maxHeight: 80,
        width: "100%",
      },
    },
  },
  createResponsiveStyle(ScreenSize.Small, {
    $nest: {
      "& span, & span svg": {
        width: "50%",
        height: "auto",
        maxHeight: "unset",
        display: "flex",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
  })
);
