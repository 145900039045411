import React from "react";
import { style } from "../styles";

export function Divider(props: {
  style?: React.CSSProperties;
  className?: string;
}) {
  return (
    <div
      className={`${borderStyle} ${props.className || ""}`}
      style={{ height: 1, ...(props.style || {}) }}
    ></div>
  );
}

const borderStyle = style({
  borderTop: "1px solid rgba(0,0,0,.2)",
  height: 1,
});
