import { NeutralColors } from "@uifabric/fluent-theme";
import { navigate } from "gatsby";
import {
  IconButton,
  Pivot,
  PivotItem,
  PrimaryButton,
  Text,
} from "office-ui-fabric-react";
import React from "react";
import { Strings } from "../Strings";

export default function NavigationBar(props: { selectedKey?: string }) {
  return (
    <div className="ms-Grid" dir="ltr">
      <div className="ms-Grid-row ms-hiddenLgUp">
        <IconButton
          iconProps={{ iconName: "GlobalNavButton" }}
          className="ms-Grid-col"
          title="Emoji"
          ariaLabel="Emoji"
          menuProps={{
            isBeakVisible: false,
            items: navLinks
              .map(item => ({
                key: item.title,
                text: item.title,
                href: item.url,
              }))
              .concat([
                {
                  key: Strings.navigation.ContactUs,
                  text: Strings.navigation.ContactUs,
                  href: `mailto:${Strings.ContactEmail}`,
                },
              ]),
          }}
          menuIconProps={{ iconName: "" }}
        />
        <div className="ms-Grid-col">
          <CompanyLogo />
        </div>
      </div>
      <div className="ms-Grid-row ms-hiddenMdDown">
        <div className="ms-Grid-col ms-sm0 ms-md0 ms-lg3">
          <CompanyLogo />
        </div>
        <Pivot
          defaultSelectedKey={props.selectedKey || navLinks[0].title}
          dir="ltr"
          className="ms-normalize ms-Grid-col ms-md8 ms-lg7 ms-textAlignRight"
          onLinkClick={item => navigate(item.props.dir)}
        >
          {navLinks.map(item => (
            <PivotItem
              key={item.title}
              headerText={item.title}
              itemKey={item.title}
              dir={item.url}
            />
          ))}
        </Pivot>
        <PrimaryButton
          styles={{
            root: { backgroundColor: NeutralColors.gray180, padding: 20 },
            rootHovered: { backgroundColor: NeutralColors.gray140 },
          }}
          className="ms-Grid-col ms-md3 ms-lg2 ms-noWrap"
          href={`mailto:${Strings.ContactEmail}`}
        >
          {Strings.navigation.ContactUs}
        </PrimaryButton>
      </div>
    </div>
  );
}

function CompanyLogo() {
  return (
    <Text
      variant="xxLargePlus"
      styles={titleStyle}
      onClick={() => (document.location.href = "/")}
    >
      {Strings.CompanyName}
    </Text>
  );
}

const navLinks = [
  { title: Strings.navigation.Home, url: "/" },
  { title: Strings.navigation.Services, url: "#services" },
];

const titleStyle = {
  root: {
    fontFamily: "Coves Light",
    cursor: "pointer",
  },
};
