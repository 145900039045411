import "office-ui-fabric-core/dist/css/fabric.min.css";
import * as typestyle from "typestyle";
import { createTypeStyle } from "typestyle";

// Gatsby does not server render the page in local dev mode
// hence we use default typestyle that injects itself in the page.
const isDevMode =
  typeof window !== "undefined" && window.location.hostname === "localhost";

const typeStyle = isDevMode ? typestyle : createTypeStyle();

const style = typeStyle.style;
const cssRule = typeStyle.cssRule;
const fontFace = typeStyle.fontFace;
const getStyles = typeStyle.getStyles;

export * from "typestyle";
export { style };
export { cssRule };
export { fontFace };
export { getStyles };
