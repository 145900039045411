import {
  D365TalentLearnIcon,
  FinancialSolidIcon,
  FlowChartIcon,
  ThunderstormsIcon,
} from "@fluentui/react-icons";
import React from "react";
import { Strings } from "../../../Strings";
import { Spacing } from "../../../styles/Spacing";
import { OfferingFeatureCard } from "../../OfferingFeatureCard";
import { ResponsiveGrid } from "../../ResponsiveGrid";

export function Offerings() {
  return (
    <div id="services">
      <div
        className={`ms-textAlignCenter`}
        style={{ marginBottom: Spacing.xLarge }}
      >
        <h2>{Strings.pages.home.TransformBusinessTitle}</h2>
        <div>{Strings.pages.home.TransformBusinessText}</div>
      </div>
      <ResponsiveGrid snapSize={2}>
        <OfferingFeatureCard
          icon={<ThunderstormsIcon />}
          title={Strings.pages.home.InnovateHybridCloudTitle}
          text={Strings.pages.home.InnovateHybridCloudText}
        />
        <OfferingFeatureCard
          icon={<FinancialSolidIcon />}
          title={Strings.pages.home.OptimizeTechnicalInvestmentTitle}
          text={Strings.pages.home.OptimizeTechnicalInvestmentText}
        />
        <OfferingFeatureCard
          icon={<FlowChartIcon />}
          title={Strings.pages.home.InnovateRelentlesslyTitle}
          text={Strings.pages.home.InnovateRelentlesslyText}
        />
        <OfferingFeatureCard
          icon={<D365TalentLearnIcon />}
          title={Strings.pages.home.InvestIntellectTitle}
          text={Strings.pages.home.InvestIntellectText}
        />
      </ResponsiveGrid>
    </div>
  );
}
