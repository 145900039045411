import { Stack } from "office-ui-fabric-react";
import React from "react";
import { Divider } from "../components/Divider";
import { PageLayout } from "../components/PageLayout";
import { DataDrivenBanner } from "../components/pages/home/DataDrivenBanner";
import { HeroBanner } from "../components/pages/home/HeroBanner";
import { Offerings } from "../components/pages/home/Offerings";
import { Strings } from "../Strings";
import "../styles/DocumentStyles";

export default function App() {
  return (
    <PageLayout title={Strings.CompanyName}>
      <Stack verticalFill gap={15}>
        <HeroBanner />
        <Divider className="ms-hiddenXlUp" />
        <Offerings />
        <Divider />
        <DataDrivenBanner />
        <Divider />
      </Stack>
    </PageLayout>
  );
}
