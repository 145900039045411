import { media, types } from "./";
import { ScreenSize } from "./ScreenSize";

type NestedCSSProperties = types.NestedCSSProperties;
type MediaQuery = types.MediaQuery;

const screenMediaQueries: MediaQuery[] = [
  { maxWidth: 479.99999 }, // Small
  { minWidth: 480, maxWidth: 639.99999 }, // Medium
  { minWidth: 640, maxWidth: 1023.99999 }, // Large
  { minWidth: 1024, maxWidth: 1365.99999 }, // xLarge
  { minWidth: 1366, maxWidth: 1919.99999 }, // xxLarge
  { minWidth: 1920 }, // xxxLarge
];

export function createResponsiveStyle(
  size: ScreenSize,
  css: NestedCSSProperties,
  applyToHigherSizes: boolean = false
): NestedCSSProperties {
  // If small and up are enabled, no need for media query
  if (size === ScreenSize.Small && applyToHigherSizes) {
    return css;
  }

  if (applyToHigherSizes) {
    return media({ minWidth: screenMediaQueries[size].minWidth }, css);
  }

  return media(screenMediaQueries[size], css);
}
